// import logo from './logo.svg';
import { useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import LearningContent from './pages/LearningContent';
import WorkStyles from './pages/WorkStyles';
import Features from './pages/Features';
import HighSchool from './pages/HighSchool';
import Global from './pages/Global';
import Corporate from './pages/Corporate';
import Government from './pages/Government';
import Concept from './pages/Concept';
import Admission from './pages/Admission';
import Contact from './pages/Contact';
import ContactConfirm from './pages/ContactConfirm';
import FloatingActionButtons from './components/FloatingActionButtons';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-20ELBTJD7W', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return (
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/learning-content" element={<LearningContent />} />
          <Route path="/work-styles" element={<WorkStyles />} />
          <Route path="/features" element={<Features />} />
          <Route path="/high-school" element={<HighSchool />} />
          <Route path="/global" element={<Global />} />
          <Route path="/corporate" element={<Corporate />} />
          <Route path="/government" element={<Government />} />
          <Route path="/concept" element={<Concept />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact-confirm" element={<ContactConfirm />} />
        </Routes>
        {/* モバイル用の問い合わせボタンを表示 */}
        <FloatingActionButtons />
        <Footer />
      </div>
  );
};

export default App;
