import React from 'react';
import './LearningContent.css';
import topImage01 from '../assets/images/learningcontent/learningcontent-image-top.jpeg';
import FlowSection from './FlowSection';
import FeaturesSection from './FeaturesSection';
import VideoContentsSection from './VideoContentSection';
import CourseDetail from './CourseDetail';
import CostTable from './CostTable';
import LearningPlanSection from './LearningPlanSection';
import ContactButtonSection from './ContactButtonSection';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LearningContent = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <div className="learning-content">
      <div className="page-title">
        <h1>学習内容について</h1>
        <p>Learning contents</p>
      </div>
      <div className='learning-image-container'>
        <img src={ topImage01 } alt="学習内容" className="top-image" />
      </div>
      <section id="flow-section">
        <FlowSection />
      </section>
      <section id="features-section">
        <FeaturesSection />
      </section>
      <section id="video-contents-section">
        <VideoContentsSection />
      </section>
      <section id="course-detail-section">
        <CourseDetail />
      </section>
      <section id="cost-table-section">
        <CostTable />
      </section>

      <section id="learning-plan-section">
        <LearningPlanSection />
      </section>

      <div class="top-contact-button-section">
        <ContactButtonSection />
      </div>

    </div>
  );
};

export default LearningContent;