// LearningPlanSection.js

import React from 'react';
import './LearningPlanSection.css';

// 画像をインポート
import LearningPlanImage01 from '../assets/images/learningcontent/learning-plan-01.png';
import LearningPlanImage02 from '../assets/images/learningcontent/learning-plan-02.png';
import LearningPlanImage03 from '../assets/images/learningcontent/learning-plan-03.png';
import LearningPlanImage04 from '../assets/images/learningcontent/learning-plan-04.png';
import LearningPlanImage05 from '../assets/images/learningcontent/learning-plan-05.png';
import LearningPlanImage06 from '../assets/images/learningcontent/learning-plan-06.png';

const LearningPlanSection = () => {
  const LearningPlanSection = [
    { id: 1, title: '短期集中型 : 前半インプット', description: ['AI開発者の場合','昼間に時間が取れる方向けです。','1ヶ月で前半が完了する計画です。'], image: LearningPlanImage01 },
    { id: 2, title: '短期集中型 : 後半実務実習', description: ['AI開発者の場合','昼間に時間が取れる方向けです。','3ヶ月で後半が完了する計画です。'], image: LearningPlanImage02 },
    { id: 3, title: '長期夜間型 : 前半インプット', description: ['AI開発者の場合','仕事をしながら勉強する方向けです。','3ヶ月で前半が完了する計画です。'], image: LearningPlanImage03 },
	{ id: 1, title: '長期夜間型 : 後半実務実習', description: ['AI開発者の場合','仕事をしながら勉強する方向けです。','6ヶ月で後半が完了する計画です。'], image: LearningPlanImage04 },
    { id: 2, title: '学生対象者型 : 前半インプット', description: ['AI開発者の場合','高校生、大学生、または15歳から24歳の仕事をしていない方向けです。','3ヶ月で前半が完了する計画です。'], image: LearningPlanImage05 },
    { id: 3, title: '学生対象者型 : 後半実務実習', description: ['AI開発者の場合','高校生、大学生、または15歳から24歳の仕事をしていない方向けです。','9ヶ月で後半が完了する計画です。'], image: LearningPlanImage06 },
  ];

  return (
    <div className='learning-plan-section'>
      <h2 className='learning-plan-title'>Learning Plan</h2>
      <p className='learning-plan-description'>個別に学習スケジュールを組むことができます。</p>

      <div className='learning-plan-container'>
        {LearningPlanSection.map((feature) => (
          <div key={feature.id} className='learning-plan-item'>
            <h3><i className='fa-solid fa-circle'></i>{feature.title}</h3>
            <p>
				{feature.description.map((line, index) => (
					<React.Fragment key={index}>
						{line}
						<br />
					</React.Fragment>
				))}
			</p>
            <img src={feature.image} alt={feature.title} className='learning-plan-image' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LearningPlanSection;
