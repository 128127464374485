import React from "react";
import "./CaseStudySection.css"; // CSSファイルをインポート

// 必要な画像をインポート
import mentoringImage1 from "../assets/images/workstyles/case-study-01.jpeg";
import mentoringImage2 from "../assets/images/workstyles/case-study-02.jpeg";
import mentoringImage3 from "../assets/images/workstyles/case-study-03.jpeg";
import mentoringImage4 from "../assets/images/workstyles/case-study-04.jpeg";
import mentoringImage5 from "../assets/images/workstyles/case-study-05.jpeg";
import mentoringImage6 from "../assets/images/workstyles/case-study-06.png";
import mentoringImage7 from "../assets/images/workstyles/case-study-07.jpeg";
import mentoringImage8 from "../assets/images/workstyles/case-study-08.jpeg";

// サブブロックのデータ
const subBlocks = [
  {
    id: 1,
    title: "鈴木さん（28歳 / 一般企業の営業職）",
    description: "転職でステップアップ",
    image: mentoringImage1,
    subItems: [
      "【現在の状況】営業ノルマに追われ、自分の成長が感じられず転職を考えている。ITに興味はあるが、未経験で不安。両親が住む地元の町が気になり、いつか帰りたいと考えている。",
      "【当校での学び】メタバース空間を活用したWebアプリ開発コースを受講。チーム開発や地方自治体との実案件に取り組むことで、現実の課題を解決しながら実践力を習得。地元のIT活用ニーズや地域課題にも視野が広がった。",
      "【卒業後の働き方】IT企業の開発職へ転職し、年収アップとリモートワークを両立。実務での自由度が増し、休日は地元に戻って家族と過ごすことが可能に。将来は完全リモートで地元に移住し、地域のプロジェクトにも参加して地方創生に貢献したいと考えている。",
    ],
  },
  {
    id: 2,
    title: "佐藤さん（32歳 / 元コンサルタント）",
    description: "起業して新規サービスを作る",
    image: mentoringImage2,
    subItems: [
      "【現在の状況】大手企業で働いていたが、自分のビジネスを立ち上げたいという思いが強くなった。",
      "【当校での学び】起業家育成プログラム（オプション）を受講し、ビジネスプランの作成や資金調達に至るまでを体系的に習得。最終発表を通じて事業プランを練り上げるとともに、外部メンターから実践的なフィードバックを受ける。",
      "【卒業後の働き方】メタバースを活用した観光支援サービスを起業。発表で得たアドバイスをもとにプロダクトを磨き上げ、地域の観光協会や企業と連携しながら新たな観光体験を提供。DX化が遅れている地方のインフラを活性化しつつ、自らのビジネスを成長させている。",
    ],
  },
  {
    id: 3,
    title: "木下さん（29歳 / 派遣社員）",
    description: "フリーランスで自由に働く",
    image: mentoringImage3,
    subItems: [
		"【現在の状況】契約社員として働いていたが、次の契約更新が不透明で将来に不安を感じている。安定して長く稼げるスキルを手に入れたいと考え、IT領域に関心を抱いた。",
		"【当校での学び】AI開発コースで機械学習やデータ分析の実装方法を基礎から身につける。メタバース空間で行われる国際プロジェクトにも参加し、英語を活用したグローバルなチーム開発を体験。",
		"【卒業後の働き方】フリーランスとしてAI関連の案件を国内外から受注。報酬単価が高い案件も獲得できるようになり、働く時間や場所を自由に調整。世界各地のクライアントとリモート契約を行いながら、収入とライフスタイルの両面で大きな自由を得ている。"
	],
  },
  {
    id: 4,
    title: "松本さん（18歳 / 高校3年生）",
    description: "就職で早期キャリアをスタート",
    image: mentoringImage4,
    subItems: [
      "【現在の状況】大学進学も考えたが、実践的なITスキルをできるだけ早く身につけたいという気持ちが強い。特にAIやデータ分析など、最先端の技術に興味を持っている。",
      "【当校での学び】AI開発者コースを受講。Pythonを使った機械学習の基礎から、データ分析、モデル構築といった実案件に取り組み、ポートフォリオを充実させる。地元の自治体や企業が抱える課題にも触れることで、AIの実用的な活用例を学ぶ。",
      "【卒業後の働き方】地元企業のIT部門へ就職し、若いうちからAI関連のプロジェクトを任される。大量のデータ処理や業務自動化を通じて実力を評価され、先輩やクライアントからも頼りにされる存在へ成長。さらに、自らのスキルを活かして地域課題の解決にも貢献している。",
    ],
  },
  {
    id: 5,
    title: "大野さん（20歳 / 大学2年生）",
    description: "起業で自分のアイデアを形に",
    image: mentoringImage5,
    subItems: [
      "【現在の状況】サークル活動でアプリ開発に触れたことで、自分のアイデアをサービス化する楽しさに目覚める。将来的には学生のうちから起業したいという強い意欲を持っている。",
      "【当校での学び】ゲームアプリ開発コースと起業家育成プログラムを同時に受講。プログラミング技術だけでなく、ビジネスモデル設計やマーケティングのノウハウも学び、事業を成功させるための全体像を把握。",
      "【卒業後の働き方】在学中に開発したゲームアプリをベースに起業。SNSやオンラインコミュニティを活用して国内外のユーザーを取り込み、メタバースを使った新しい遊び方のプロジェクトにも着手。若い感性とITスキルを武器に、次世代のエンターテインメント市場へ挑戦している。",
    ],
  },
  {
    id: 6,
    title: "北川さん（29歳 / 育児中の主夫）",
    description: "フリーランスで柔軟に働く",
    image: mentoringImage6,
    subItems: [
		"【現在の状況】育児を最優先しており、フルタイムで働くのは難しいが、自分のキャリアを諦めたくない。家事や育児をこなしながら収入を得る方法を模索している。",
		"【当校での学び】データサイエンティストコースでオンライン学習を進めつつ、メタバース空間での実践プロジェクトにも参加。チーム開発の進め方を学びながら、英語学習メタバースで海外エンジニアとのコミュニケーションも経験。",
		"【卒業後の働き方】フリーランスとしてデータ分析の案件を請け負い、週2〜3日程度の稼働で収入を確保。育児スケジュールに合わせて仕事時間を調整できるため、子どもとの時間をしっかり取りつつ、自らのスキルも磨き続けるワークスタイルを実現できる。",
	],
  },
  {
    id: 7,
    title: "安藤さん（37歳 / 主婦）",
    description: "複業で収入アップ＋家庭と両立",
    image: mentoringImage7,
    subItems: [
      "【現在の状況】子どもの成長に合わせて家計をサポートしたいが、フルタイム勤務は負担が大きいと感じている。在宅でスキルを活かせる仕事を求めている。",
      "【当校での学び】CG・アニメーションクリエイターコースを受講。動画編集やモーショングラフィックスなど、在宅で完結できるスキルを重点的に習得。自治体が行うPR動画の制作にも携わり、自信を高める。",
      "【卒業後の働き方】子どもが学校へ行っている間や夜の空き時間を活用して、フリーランスとして映像編集やCG制作を請け負う。家族の生活リズムに合わせた柔軟な働き方が可能になり、安定した副収入を確保しながらやりがいも感じている。",
    ],
  },
  {
    id: 8,
    title: "山田さん（26歳 / 無職）",
    description: "就職して安定収入を得る",
    image: mentoringImage8,
    subItems: [
      "【現在の状況】就職活動に苦戦しており、都市部の競争が激しい中で希望する仕事が見つからない。ITスキルを身につければ選択肢が広がるのではと考え、同時に自然豊かな地方で心機一転新生活を始めたい思いがある。",
      "【当校での学び】Webアプリ開発者コースを受講。自治体との連携プロジェクトで地域課題を解決するアプリを開発することで、自信と実務経験を獲得。メタバース空間でのチーム開発も体験し、リモートワークに必要なコミュニケーションスキルを磨く。",
      "【卒業後の働き方】ITベンチャーに正社員として入社が決まり、地方移住を実現。リモートワークを主体とした働き方で地元企業と協働するプロジェクトにも参加しながら、自然に囲まれた環境で生活を楽しむ。都市部と地方をオンラインで繋ぐ橋渡し的な役割を担い、安定収入と充実した地域ライフの両立を叶えている。",
    ],
  },
];

const CaseStudySection = () => {
  return (
    <div className="case-study-section">
      <h2 className="case-study-title">Case Study</h2>
      <p>働き方の具体例です。</p>

      <div className="case-study-container">
        {subBlocks.map((subBlock) => (
          <div key={subBlock.id} className="case-study-block">
            <h3>{subBlock.title}</h3>
            <div className="case-study-content">
              <img src={subBlock.image} alt={subBlock.title} />
			  <div className="case-study-text">
				<p>{subBlock.description}</p>
				<ul>
					{subBlock.subItems.map((item, idx) => (
					<li key={idx}>
						<i className="fa-solid fa-circle"></i> {item}
					</li>
					))}
				</ul>
			  </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseStudySection;