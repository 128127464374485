import React from "react";
import "./FloatingActionButtons.css";

const FloatingActionButtons = () => {
  return (
    <div className="floating-buttons">
      <a href="https://lin.ee/zMSq1J9" className="fab-item">
	  	<i className="fas fa-comment"></i>
        <span>LINE</span>
      </a>
      <a href="https://forms.gle/LA9Qw3ZRp6Dhb8j56" className="fab-item">
	  	<i className="fas fa-question"></i>
        <span>お問合わせ</span>
      </a>
      <a href="https://forms.gle/X2kNRDFAY7YfyoNF8" className="fab-item">
	  	<i className="fas fa-file"></i>
        <span>資料請求</span>
      </a>
    </div>
  );
};

export default FloatingActionButtons;