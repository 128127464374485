import React from 'react';
import './FeaturesSection.css';

// 画像をインポート
import FeaturesSectionImage01 from '../assets/images/learningcontent/features-image-01.jpeg';
import FeaturesSectionImage02 from '../assets/images/learningcontent/features-image-02.jpeg';
import FeaturesSectionImage03 from '../assets/images/learningcontent/features-image-03.jpeg';
import FeaturesSectionImage04 from '../assets/images/learningcontent/features-image-04.jpeg';
import FeaturesSectionImage05 from '../assets/images/learningcontent/features-image-05.jpeg';

const featuresSection = () => {
  const features = [
    { id: 1, title: '起業支援', description: '起業するためのビジネスプランを作成し、出資の機会を得られます。オプションのカリキュラムで追加費用がかかります。', image: FeaturesSectionImage01 },
    { id: 2, title: 'メタバース語学学習', description: '自宅にいながら、いつでもネイティブの方々と実践的な英会話を勉強できます。この学校に通う方は無料で3ヶ月使用することができます。', image: FeaturesSectionImage02 },
    { id: 3, title: '交流会', description: '様々な年代の生徒同士で交流をすることで、勉強仲間や仕事仲間が見つかります。卒業後も様々なイベントに参加することができます。無料です。', image: FeaturesSectionImage03 },
    { id: 4, title: '部活', description: '気の合う仲間と一緒に、好きなことで時間を共有できます。主に高校生、大学生向けですが、社会人の方も参加することができます。無料で自由参加です。卒業後も参加できます。', image: FeaturesSectionImage04 },
    { id: 5, title: '文化祭', description: '自分の作ったものやみんなで作ったもの展示して、楽しい経験と思い出をつくります。主に高校生、大学生向けですが、社会人の方も参加することができます。', image: FeaturesSectionImage05 },
  ];

  return (
    <div className='features-section'>
      <h2 className='features-title'>Benefits</h2>
      <p>学校生活をより楽しみ、レベルアップするためのカリキュラムがあります。</p>
      <div className='features-grid'>
        {features.map((features) => (
          <div key={features.id} className='features-item'>
            <img src={features.image} alt={features.title} className='features-image' />
            <h3><i class="fa-solid fa-circle"></i>{features.title}</h3>
            <p>{features.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default featuresSection;