import React from 'react';
import './CampaignSection.css';
// import { FaFilePdf } from 'react-icons/fa';

const CampaignSection = () => {
    const targetDate = new Date('2025-03-20');
    const today = new Date();

    // 残り日数を計算
    const remainingDays = Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));

    return (
        <div className="campaign-section">
            <h2 className="campaign-title">Campaign</h2>
            <p className="campaign-description">期間限定のキャンペーンです。</p>
            <div className="campaign-cards">
                <h3 className="campaign-text">
                    初年度入学キャンペーン
                </h3>
                <p className="campaign-list">
                <i className="fa-solid fa-circle"></i>2025年4月、学校がスタートします。<br />
                <i className="fa-solid fa-circle"></i>2025年3月20日までに入学していただいた方は、<strong>コース費用が半額</strong>（定員40名）になります。<br />
                <i className="fa-solid fa-circle"></i>2025年4月以降、および定員に達した場合は、通常のコース費用が適用されます。<br />
                <i className="fa-solid fa-circle"></i>通常の入学は、1年中いつでも可能です。
                </p>
                <p>プラス</p>
                <p className="campaign-list">
                {/* <i className="fa-solid fa-circle"></i><strong>2025年1月28日</strong>までに入学した方。就職または転職で<strong>さらに半額</strong>キャッシュバック。<br /> */}
                <i className="fa-solid fa-circle"></i><strong>2025年2月24日</strong>までに入学した方。就職または転職で<strong>さらに30%</strong>キャッシュバック。<br />
                <i className="fa-solid fa-circle"></i><strong>2025年3月20日</strong>までに入学した方。就職または転職で<strong>さらに20%</strong>キャッシュバック。<br />
                </p>
                {/* 残り日数を表示 */}
                <p className="remaining-days">
                    残りあと{remainingDays}日
                </p>
                {/* <div className="campaign-action">
                    <a href="/path/to/campaign-details.pdf" target="_blank" rel="noopener noreferrer" className="details-button">
                        詳しく見る [PDF] <FaFilePdf className="pdf-icon" />
                    </a>
                </div> */}
            </div>
        </div>
    );
};

export default CampaignSection;