// FlowSection.js

import React, { useState, useRef, useEffect } from "react";
import "./FlowSection.css"; // CSSファイルをインポート

// 必要な画像をインポート（例として仮の画像パスを使用）
import mentoringImage1 from "../assets/images/top/flow-image-01.png";
import mentoringImage2 from "../assets/images/top/flow-image-02.png";
import mentoringImage3 from "../assets/images/top/flow-image-03.png";
import mentoringImage4 from "../assets/images/top/flow-image-04.png";
import mentoringImage5 from "../assets/images/top/flow-image-05.png";
import mentoringImage6 from "../assets/images/top/flow-image-06.png";
import mentoringImage7 from "../assets/images/top/flow-image-07.png";
import mentoringImage8 from "../assets/images/top/flow-image-08.png";
import mentoringImage9 from "../assets/images/top/flow-image-09.png";
import mentoringImage10 from "../assets/images/top/flow-image-10.png";
import mentoringImage11 from "../assets/images/top/flow-image-11.png";
import mentoringImage12 from "../assets/images/top/flow-image-12.png";


// メイnブロックのデータ
const mainBlocks = [
  { id: 1, title: "1. メンタリング" },
  { id: 2, title: "2. インプット" },
  { id: 3, title: "3. 実務実習" },
  { id: 4, title: "4. 卒業" },
];

const subBlocks = {
    1: [
        {
            id: 1,
            title: "目標設定",
            description: "まず初めに、目標を設定します。",
            image: mentoringImage1,
            subItems: ["どんな仕事に就きたいか", "どんな人間になりたいか", "どれくらいの期間で終了するか"],
        },
        {
            id: 2,
            title: "科目選択",
            description: "目標を達成するために、適切な科目を選択します。",
            image: mentoringImage2,
            subItems: ["レベルに合わせた授業選択", "目標に合わせた授業選択", "複数のジャンルにまたがることも可能"],
        },
    ],
    2: [
        {
            id: 3,
            title: "動画授業",
            description: "自分の好きな時間で授業が受けられます。",
            image: mentoringImage3,
            subItems: ["好きな時間に", "好きな場所で", "何度でも"],
        },
        {
            id: 4,
            title: "進捗管理",
            description: "サポーターが進捗を管理し、モチベーションを保ちます。",
            image: mentoringImage4,
            subItems: ["サポーターによる進捗確認", "課題によるスキルチェック", "メンタルサポーターへの相談"],
        },
        {
            id: 5,
            title: "技術サポート",
            description: "つまったら、講師にいつでも相談できます。",
            image: mentoringImage5,
            subItems: ["エンジニア講師によるサポート", "チャットでいつでも相談", "独学よりも成長スピードが早い"],
        },
    ],
    3: [
        {
            id: 6,
            title: "課題解決ブレスト",
            description: "課題解決するためのプロダクト構想を考えます。",
            image: mentoringImage6,
            subItems: ["課題の洗い出し", "課題解決方法の立案", "要件定義の作成"],
        },
        {
            id: 7,
            title: "チーム編成(or 個人開発)",
            description: "様々な技術を持ったメンバーとチームを組みます。",
            image: mentoringImage7,
            subItems: ["課題解決するためのチーム", "それぞれの役割を決める", "プロジェクトマネージャーを決める", "＊個人開発を選択することも可能です"],
        },
        {
            id: 8,
            title: "定期の進捗会議",
            description: "実際の開発同様、進捗会議でプロジェクトを管理します。",
            image: mentoringImage8,
            subItems: ["チーム開発を経験できる", "講師や同僚と助け合う", "開発時の計画を管理する"],
        },
        {
            id: 9,
            title: "講師サポート",
            description: "開発で詰まっても、講師のサポートで早期に解決できます。",
            image: mentoringImage9,
            subItems: ["開発で詰まった時に質問できる", "自分で考えると何時間もかかるが、早期に解決できる", "成長スピードが早くなる"],
        },
    ],
    4: [
        {
            id: 10,
            title: "働き方が選べる",
            description: "様々な働き方が選べます。",
            image: mentoringImage10,
            subItems: ["就職、起業、フリーランスなど", "時間や場所にとらわれない", "リアルとメタバースで働ける"],
        },
        {
            id: 11,
            title: "収入アップ",
            description: "転職や複業での収入アップが可能です。",
            image: mentoringImage11,
            subItems: ["年収600万-800万も目指せる", "エンジニアは足りていない", "複業で、補助的に収入を確保する"],
        },
        {
            id: 12,
            title: "資格の取得",
            description: "スキルの証明をすることで、信頼性がアップします。",
            image: mentoringImage12,
            subItems: ["スキルを証明する資格がもらえる", "仕事の提供を受けられる", "就職に有利になる"],
        },
    ],
};

const FlowSection = () => {
    const initialIndices = {};
    mainBlocks.forEach((block) => {
        initialIndices[block.id] = 0;
    });

    const [currentSubBlockIndices, setCurrentSubBlockIndices] = useState(initialIndices);
    const subBlockContainerRefs = useRef({});
    const scrollTimeouts = useRef({});

    // スクロールイベントのハンドラ
    const handleScroll = (blockId) => {
        const container = subBlockContainerRefs.current[blockId];
        if (container) {
            const scrollLeft = container.scrollLeft;
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            const subBlockElements = container.querySelectorAll(".sub-block");
            if (subBlockElements.length > 0) {
                const subBlockWidth = subBlockElements[0].offsetWidth;
                const gap = parseInt(window.getComputedStyle(container).gap) || 0;
                const totalWidth = subBlockWidth + gap;

                let index = Math.round(scrollLeft / totalWidth);

                const maxIndex = subBlockElements.length - 1;

                // スクロール位置が最大スクロール位置に近い場合
                if (Math.abs(scrollLeft - maxScrollLeft) < 5) {
                    index = maxIndex;
                }

                const clampedIndex = Math.min(Math.max(index, 0), maxIndex);

                setCurrentSubBlockIndices((prev) => ({
                    ...prev,
                    [blockId]: clampedIndex,
                }));
            }
        }
    };

    // スクロールイベントを追加
    useEffect(() => {
        mainBlocks.forEach((block) => {
            const container = subBlockContainerRefs.current[block.id];
            if (container) {
                const onScroll = () => {
                    // スクロール中に何度も状態を更新しないようにタイマーを使用
                    if (scrollTimeouts.current[block.id]) {
                        clearTimeout(scrollTimeouts.current[block.id]);
                    }
                    scrollTimeouts.current[block.id] = setTimeout(() => {
                        handleScroll(block.id);
                    }, 100);
                };
                container.addEventListener("scroll", onScroll);
                return () => {
                    container.removeEventListener("scroll", onScroll);
                };
            }
        });
    }, []);

    const handleNext = (blockId) => {
        const subBlocksArray = subBlocks[blockId];
        const currentIndex = currentSubBlockIndices[blockId] || 0;
        if (currentIndex < subBlocksArray.length - 1) {
            const newIndex = currentIndex + 1;
            setCurrentSubBlockIndices((prev) => ({ ...prev, [blockId]: newIndex }));
            scrollToSubBlock(blockId, newIndex);
        }
    };

    const handlePrev = (blockId) => {
        const currentIndex = currentSubBlockIndices[blockId] || 0;
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setCurrentSubBlockIndices((prev) => ({ ...prev, [blockId]: newIndex }));
            scrollToSubBlock(blockId, newIndex);
        }
    };

    const scrollToSubBlock = (blockId, index) => {
        const container = subBlockContainerRefs.current[blockId];
        if (container) {
            const subBlockElements = container.querySelectorAll(".sub-block");
            if (subBlockElements.length > 0) {
                const subBlockWidth = subBlockElements[0].offsetWidth;
                const gap = parseInt(window.getComputedStyle(container).gap) || 0;
                const totalWidth = subBlockWidth + gap;
                const targetPosition = totalWidth * index;

                container.scrollTo({
                    left: targetPosition,
                    behavior: "smooth",
                });
            }
        }
    };

    const handleDotClick = (blockId, index) => {
        setCurrentSubBlockIndices((prev) => ({ ...prev, [blockId]: index }));
        scrollToSubBlock(blockId, index);
    };

    return (
        <div className="flow-section">
            <h2 className="flow-title">Flow</h2>
            <p>入学から卒業までの流れ</p>

            <div className="flow-container">
                <div className="main-flow">
                    {mainBlocks.map((block) => (
                        <React.Fragment key={block.id}>
                            {/* メインブロックの詳細を表示 */}
                            <div className="flow-block">
                                <h3>{block.title}</h3>
                            </div>

                            {/* サブブロックの詳細を表示 */}
                            {subBlocks[block.id] && (
                            <div className="sub-block-wrapper">
                                {/* 左矢印 */}
                                {(currentSubBlockIndices[block.id] ?? 0) > 0 && (
                                    <div 
                                        className="arrow left-arrow" 
                                        onClick={() => handlePrev(block.id)}
                                    >
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </div>
                                )}

                                <div 
                                    className="sub-block-container"
                                    ref={(el) => (subBlockContainerRefs.current[block.id] = el)}
                                >
                                    {subBlocks[block.id].map((subBlock) => (
                                        <div 
                                            key={subBlock.id} 
                                            className="sub-block horizontal-layout"
                                        >
                                            <div className="sub-content-left">
                                                <h4>{subBlock.title}</h4>
                                                <div className="sub-image">
                                                    <img src={subBlock.image} alt={subBlock.title} />
                                                </div>
                                            </div>
                                            <div className="sub-content-right">
                                                <p>{subBlock.description}</p>
                                                <ul>
                                                    {subBlock.subItems.map((item, idx) => (
                                                        <li key={idx}><i class="fa-solid fa-circle"></i>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* 右矢印 */}
                                {(currentSubBlockIndices[block.id] ?? 0) < 
                                    subBlocks[block.id].length - 1 && (
                                    <div 
                                        className="arrow right-arrow" 
                                        onClick={() => handleNext(block.id)}
                                    >
                                        <i class="fa-solid fa-chevron-right"></i>
                                    </div>
                                )}

                                {/* ドットナビゲーション */}
                                <div className="dots-container">
                                    {subBlocks[block.id].map((_, idx) => (
                                        <span
                                            key={idx}
                                            className={`dot ${
                                                currentSubBlockIndices[block.id] === idx
                                                    ? "active" 
                                                    : ""
                                                }`}
                                            onClick={() => handleDotClick(block.id, idx)}
                                        ></span>
                                    ))}
                                    </div>
                            </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FlowSection;